var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          key: _vm.key,
          ref: "ruleForm",
          attrs: { model: _vm.value, rules: _vm.rules },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "所属品牌", prop: "brandCode" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getChange("brand", $event)
                            },
                          },
                          model: {
                            value: _vm.value.brandCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "brandCode", $$v)
                            },
                            expression: "value.brandCode",
                          },
                        },
                        _vm._l(_vm.brandOptions, function (a, b) {
                          return _c("el-option", {
                            key: b,
                            attrs: { label: a.dictValue, value: a.dictCode },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "所属系列", prop: "seriesCode" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getChange("serize", $event)
                            },
                          },
                          model: {
                            value: _vm.value.seriesCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "seriesCode", $$v)
                            },
                            expression: "value.seriesCode",
                          },
                        },
                        _vm._l(_vm.serizeOptions, function (a, b) {
                          return _c("el-option", {
                            key: b,
                            attrs: { label: a.dictValue, value: a.dictCode },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "竞品名称", prop: "productName" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.value.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "productName", $$v)
                          },
                          expression: "value.productName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }