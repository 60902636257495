<template>
  <div>
    <el-form :model="value" :rules="rules" ref="ruleForm" :key="key">
      <el-row :gutter="24">
        <el-col :md="12">
          <el-form-item label="所属品牌" class="flex" prop="brandCode">
            <el-select
              :disabled="disabled"
              v-model="value.brandCode"
              @change="getChange('brand', $event)"
              placeholder="请选择">
              <el-option
                v-for="(a, b) in brandOptions"
                :key="b"
                :label="a.dictValue"
                :value="a.dictCode">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item label="所属系列" class="flex" prop="seriesCode">
            <el-select
              v-model="value.seriesCode"
              :disabled="disabled"
              @change="getChange('serize', $event)"
              placeholder="请选择">
              <el-option
                v-for="(a, b) in serizeOptions"
                :key="b"
                :label="a.dictValue"
                :value="a.dictCode">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="12">
          <el-form-item label="竞品名称" class="flex" prop="productName">
            <el-input v-model="value.productName" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import request from '../../../../../../../utils/request';

export default {
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      key: 0,
      brandOptions: [],
      serizeOptions: [],
      rules: {
        productName: [{
          required: true, message: '请输入竞品名称', trigger: 'blur',
        }],
        brandCode: [{
          required: true, message: '请选择所属品牌', trigger: 'change',
        }],
        seriesCode: [{
          required: true, message: '请选择所属系列', trigger: 'change',
        }],
      },
    };
  },
  created() {
    this.$emit('input', this.value);
    this.getOptions();
    if (this.disabled) {
      this.rules = {};
    }
  },
  methods: {
    getChange(type, event) {
      if (type === 'brand') {
        const brandData = this.brandOptions.find((val) => val.dictCode === event);
        if (brandData) {
          this.value.brandName = brandData.dictValue;
          this.serizeOptions = brandData.children;
        }
        this.value.seriesName = null;
        this.value.seriesCode = null;
      } else {
        const serizeData = this.serizeOptions.find((val) => val.dictCode === event);
        if (serizeData) {
          this.value.seriesName = serizeData.dictValue;
        }
      }
      this.$emit('input', this.value);
      this.key += 1;
    },
    getOptions() {
      request
        .post('/mdm/mdmdictdata/tree', {
          dictTypeCode: 'compete_product_type',
        })
        .then((res) => {
          if (res.success) {
            this.brandOptions = res.result;
            if (this.value.brandCode) {
              const brandData = this.brandOptions.find((val) => val.dictCode === this.value.brandCode);
              if (brandData) {
                this.serizeOptions = brandData.children;
              }
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.el-row {
  margin: 0 !important;
}
/deep/ .el-form-item__label {
  width: 125px;
}
/deep/ .el-form-item__content {
  flex: 1;
}
/deep/ .el-textarea__inner {
  height: 80px;
}
.el-col {
  padding: 0 !important;
}
</style>
